// @i18n-ignore-all

export default [
  {
    id: '@TMC',
    ref: '/',
    path: '/travel-manage-center',
    onload: () => import('./travelManagerCenter'),
    onbefore: app => {
      return app.apply('@auth-check', 'check:auth')
    }
  },

  {
    point: '@@menus',
    onload: () => {
      return [
        {
          id: 'travel-manage-center',
          pId: 'enterprise-manage',
          permissions: ['TRIP_MANAGE'],
          powers: ['TripManage'],
          weight: 100,
          label: '差旅管理',
          href: '/travel-manage-center',
          icon: 'travel-manage-center'
        }
      ]
    }
  }
]
